export const captcha_secret = '6Lfs3P8mAAAAANN-UgSfCgILd7KMGd9FyF4uknr9';
export const captcha_key = '6Lfs3P8mAAAAAKqpEWoHxzfBNak2kV3NKiLebz3X';
export const captcha_verify_url =
  'https://www.google.com/recaptcha/api/siteverify'; // for local use http://localhost:4000
//////////////////////// URLS ////////////////////////
let backendUrl = 'https://back_dashboard.floid.app';
let widgetUrl = 'https://admin.floid.app';
let frontendUrl = 'https://dashboard.floid.app';
let downloadUrl = 'http://localhost:4005';
let env = 'local';

let frontendUrlDocker = 'http://adminapp-frontadmin-1:3000'; //3000
if (window.location.hostname === 'localhost') {
  backendUrl = 'http://localhost:4000';
  widgetUrl = 'http://admin.floid.one';
  frontendUrl = 'http://localhost:3000';
  frontendUrlDocker = 'http://adminapp-frontadmin-1:80';
  env = 'local';
} else if (window.location.hostname === 'dashboard.floid.app') {
  backendUrl = 'https://back_dashboard.floid.app';
  widgetUrl = 'https://admin.floid.app';
  frontendUrl = 'https://dashboard.floid.app';
  frontendUrlDocker = 'http://admin-frontadmin-1:80'; // 80 with buil for prod
  env = 'prodssh';
} else if (window.location.hostname === 'dashboard.floid.dev'){
  backendUrl = 'https://back_dashboard.floid.dev';
  widgetUrl = 'https://admin.floid.dev';
  frontendUrl = 'https://dashboard.floid.dev';
  frontendUrlDocker = 'http://devadmin-frontadmin-1:3000'; //http://devadmin-frontadmin-1:3000 // esto va a abrir el puppeteer
  env = 'devssh';
} else if (window.location.hostname === 'adminapp-frontadmin-1') {
  frontendUrlDocker = 'http://adminapp-frontadmin-1:80';
  env = 'local';
  backendUrl = 'http://adminapp-backadmin-1:4000';
} else if (window.location.hostname === 'devadmin-frontadmin-1') { // for dev ssh
  frontendUrlDocker = 'http://devadmin-frontadmin-1:3000';
  backendUrl = 'http://devadmin-backadmin-1:4001';
  env = 'devssh';
} else if (window.location.hostname === 'admin-frontadmin-1') { // for prod ssh
  frontendUrlDocker = 'http://admin-frontadmin-1:80'; // 80 for prod with build
  backendUrl = 'http://admin-backadmin-1:4000';
  env = 'prodssh';
}


export const backend_url = backendUrl;
export const widget_url = widgetUrl;
export const frontend_url = frontendUrl;
export const download_url = downloadUrl;
export const frontend_url_docker = frontendUrlDocker;
export const environment = env;